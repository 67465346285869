import React, { Component, Fragment } from "react";
import Modal from "react-modal";
import { SideBarLoader } from "../../helpers/Loaders";
import api from "../../../../utils/pm_project_tasks_api";
import PendingForm from "../../../CampoForm/PendingForm";
import axios from "axios";

const customStyles = {
  overlay: {
    position: "fixed",
    top: 0,
    left: "40%",
    right: 0,
    bottom: 0,
    backgroundColor: "rgba(0, 0, 0, 0.60)",
    zIndex: 400,
  },
  content: {
    position: "absolute",
    top: "0px",
    left: "0%",
    right: "0%",
    bottom: "0px",
    border: "1px solid #ccc",
    background: "#fff",
    overflow: "auto",
    WebkitOverflowScrolling: "touch",
    borderRadius: "0px",
    outline: "none",
    padding: "20px",
  },
};

const mobileCustomStyles = {
  overlay: {
    position: "fixed",
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    backgroundColor: "rgba(0, 0, 0, 0.60)",
    zIndex: 400,
  },
  content: {
    position: "absolute",
    top: "0px",
    left: "0%",
    right: "0%",
    bottom: "0px",
    border: "1px solid #ccc",
    background: "#fff",
    overflow: "auto",
    WebkitOverflowScrolling: "touch",
    borderRadius: "0px",
    outline: "none",
    padding: "20px",
  },
};

export default class PendingSubmissionModal extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: true,
      project_task: props.project_task,
      pending_submission: null,
      isMobile: false,
      form_design: [],
      report_templates: [],
      project_data_documents: {},
    };

    this.loadResource = this.loadResource.bind(this);
    this.escFunction = this.escFunction.bind(this);
    this.checkIfMobile = this.checkIfMobile.bind(this);
  }

  loadResource(id, pending_submission_id) {
    axios
      .all([
        api.getPendingSubmission(id, pending_submission_id),
        api.getForm(this.state.project_task.id),
      ])
      .then(
        axios.spread((pendingSubmissionResponse, formResponse) => {
          this.setState({
            loading: false,
            pending_submission: pendingSubmissionResponse.data,
            form_design: JSON.parse(formResponse.form_design),
            report_templates: formResponse.report_templates,
            project_data_documents: formResponse.project_data_documents,
          });
        })
      )
      .catch((error) => {
        console.error("Failed to load resources:", error);
        this.setState({ loading: false });
      });
  }

  checkIfMobile = () => {
    const isMobile = window.innerWidth <= 768; // Adjust this threshold based on your needs
    if (isMobile !== this.state.isMobile) {
      this.setState({ isMobile });
    }
  };

  escFunction(e) {
    if (e.keyCode === 27) {
      this.props.close();
    }
  }

  componentDidMount() {
    document.addEventListener("keydown", this.escFunction, false);
    this.loadResource(
      this.props.project_task.id,
      this.props.pending_submission_id
    );
    this.checkIfMobile(); // Check initial window size
    window.addEventListener("resize", this.checkIfMobile);
  }

  componentWillUnmount() {
    document.removeEventListener("keydown", this.escFunction, false);
    window.removeEventListener("resize", this.checkIfMobile);
  }

  render() {
    let { pending_submission } = this.state;

    return (
      <Fragment>
        <Modal
          style={this.state.isMobile ? mobileCustomStyles : customStyles}
          ariaHideApp={false}
          isOpen={true}
        >
          <div>
            <div>
              <button
                type="button"
                onClick={this.props.close}
                className="close"
                aria-label="Close"
              >
                <span aria-hidden="true" style={{ fontSize: "28px" }}>
                  <i className="fa fa-times"></i>
                </span>
              </button>
            </div>

            <div>
              {this.state.loading ? (
                <SideBarLoader />
              ) : (
                <div className="app pb-5 project-show">
                  <div className="container px-0">
                    {pending_submission.id && (
                      <Fragment>
                        <p className="mb-0">
                          {pending_submission.updated_at_label} by{" "}
                          {pending_submission.user.name}
                        </p>

                        <div>
                          <PendingForm
                            pending_submission={pending_submission}
                            form_design={this.state.form_design}
                            resource_type={"project_tasks"}
                            resource_id={this.state.project_task.id}
                            project_data_documents={
                              this.state.project_data_documents
                            }
                          />
                        </div>
                      </Fragment>
                    )}
                  </div>
                </div>
              )}
            </div>
          </div>
        </Modal>
      </Fragment>
    );
  }
}
