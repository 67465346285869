import React, { Component, Fragment } from 'react'
import { ShowHeaderLoader} from '../helpers/Loaders'
import { BreadcrumbsStateless, BreadcrumbsItem } from '@atlaskit/breadcrumbs';
import EditProjectTaskModal from '../project_tasks/EditProjectTaskModal.js'
import ActionProjectTaskModal from '../project_tasks/ActionProjectTaskModal.js'
import DetailsSidebar from '../common/DetailsSidebar'
import ActivitySidebar from '../common/ActivitySidebar'
import RelationshipsModal from './RelationshipsModal'
import { assignedTo } from '../helpers/groupHelpers'

import SchedulerContainer from '../scheduler/SchedulerContainer'
import SubOrganizationSchedulerContainer from '../scheduler/SubOrganizationSchedulerContainer'
import SubOrganizationFullSchedulerContainer from '../scheduler/SubOrganizationFullSchedulerContainer'

import SupportRelationshipsProjectTaskBatchModalWithSync from '../helpers/SupportRelationshipsProjectTaskBatchModalWithSync'
import Stickers from '../stickers/Stickers';
import PendingSubmissionModal from './form_task/PendingSubmissionModal'

export default class ShowHeader extends Component {
  constructor(props) {
    super(props)
    this.state = {
      open_edit_modal: false,
      open_action_modal: false,
      selected_action: null,
      open_details_modal: false,
      open_activity_modal: false,
      open_pending_submission_modal: false,
    }

    this.updateProjectTask = this.updateProjectTask.bind(this)
    this.handleBack = this.handleBack.bind(this)
    this.openActionModal = this.openActionModal.bind(this)
  }

  handleBack() {
    this.props.navigate()
  }

  openActionModal(action) {
    this.setState({
      selected_action: action,
      open_action_modal: true,
    })
  }

  updateProjectTask(project_task) {
    this.setState({open_edit_modal: false, open_action_modal: false, selected_action: null}, () => {
      this.props.updateProjectTask(project_task)
    })
  }

  updateScheduledRecords = (response) => {
    let project_task = {...this.props.project_task}
    project_task.scheduled_at = response[0].scheduled_at
    project_task.scheduled_at_label = response[0].scheduled_at_label
    project_task.user = response[0].user
    project_task.sub_organization = response[0].sub_organization
    project_task.sub_organization_show = response[0].sub_organization_show
    project_task.duration = response[0].duration
    project_task.full_scheduled_set_by_sub_org_user = response[0].full_scheduled_set_by_sub_org_user
    this.props.updateProjectTask(project_task)
  }

  render() {
    let project_task = this.props.project_task
    let { parents, project_task_type } = project_task
    let items = [
      (<BreadcrumbsItem key='bt1' onClick={() => this.props.navigate('/projects_management/projects', true)} text="Projects" />),
      (<BreadcrumbsItem key='bt2'onClick={() => this.props.navigate(`/projects_management/projects/${project_task.project.id}`, true)} text={project_task.project.name} />),
      (<BreadcrumbsItem key='bt3' onClick={() => this.props.navigate(`/projects_management/projects/${project_task.project.id}?tab=${project_task.project_group.id}`, true)} text={project_task.project_group.name} />)
    ]

    parents.forEach((parent) => {
      let path = ''
      if(parent.tree_depth % 2 == 0) {
        path = `/projects_management/project_tasks/${parent.id}`
      } else {
        path = `/projects_management/project_tasks/${parent.parent_id}?classifier_id=${parent.id}`
      }
      items.push(
        (<BreadcrumbsItem key={parent.id} onClick={() => this.props.navigate(path, true)} text={parent.name} />)
      )
    })
    let { behaviours, actions } = project_task_type
    let is_schedulable = behaviours.includes('schedulable') || behaviours.includes('scheduling_calendar')
    let is_multi_form_report = behaviours.includes('recurrent_form')
    let is_form_report = project_task.multi_form_project_task_id
    let use_task_relationships = project_task.use_task_relationship && project_task_type.possible_relationships.length > 0 && project_task.access.can_list_relationships

    let filtered_actions = actions
    let from_job = !!project_task.project_task_importer_job_id
    let adhoc = project_task.adhoc
    let name = (from_job || adhoc) ? project_task.name : (project_task.project_task_type.name || project_task.name)

    let bbStyle = {borderBottom: '1px solid #ccc'}
    if(this.props.hideBottomBorder) {
      bbStyle = {}
    }

    let classDefinition = is_multi_form_report?
        'bg-white mt-3 px-4 border-bottom border-primary' : 'bg-white my-3 px-4'

    return (
      <Fragment>
        {this.props.loading ? (
          <ShowHeaderLoader />
        ) : (
          <div className={classDefinition}>
            {this.props.navigate && (
              <div className="pm-breadcrumb reset-box-sizing">
                {is_form_report ? (
                  <Fragment>
                    Projects / {project_task.project.name} /{" "}
                    {project_task.project_group.name}
                  </Fragment>
                ) : (
                  <BreadcrumbsStateless
                    isExpanded={this.state.isExpanded}
                    onExpand={(e) => this.expand(e)}
                  >
                    {items}
                  </BreadcrumbsStateless>
                )}
              </div>
            )}
            <div className="row">
              <div className="col-md-7">
                <div className="project-meta">
                  <h4>{name}</h4>
                </div>

                {is_form_report && (
                  <p className="mb-0">
                    {project_task.created_at_label} by{" "}
                    {project_task.multi_form_reporter_user.name}
                  </p>
                )}

                {(from_job || adhoc) && (
                  <div className="project-references">
                    {project_task.note && (
                      <div
                        style={{ whiteSpace: "pre-line" }}
                        className="bg-light border border-rounded p-2 my-3"
                      >
                        <h6 className="text-secondary">
                          NOTES <span className="small">(newest first)</span>
                        </h6>
                        <span className="font-weight-light">
                          {project_task.note}
                        </span>
                      </div>
                    )}
                    <div className="row mt-2">
                      {project_task.quantity && (
                        <div className="col-md-3">
                          <span className="text-muted mr-2">QTY </span>{" "}
                          {project_task.quantity}
                        </div>
                      )}
                      {project_task.unit_cost && (
                        <div className="col-md-5">
                          <span className="text-muted mr-2">UNIT COST </span> $
                          {project_task.unit_cost}/unit
                        </div>
                      )}
                      {project_task.cost && (
                        <div className="col-md-4">
                          <span className="text-muted mr-2">COST </span>{" "}
                          {project_task.cost_label}
                        </div>
                      )}
                      {project_task.coverage && (
                        <div className="col-md-4">
                          <span className="text-muted mr-2">COVERAGE </span>{" "}
                          {project_task.coverage}
                        </div>
                      )}
                    </div>
                  </div>
                )}

                {is_multi_form_report && project_task.pending_submission_id &&
                  <div>
                    <span
                      className="cursor-pointer text-muted"
                    >
                      1 pending submission
                      <a
                        href="#"
                        className='ml-1 text-primary cursor-pointer'
                        onClick={() => this.setState({ open_pending_submission_modal: true })}
                      >
                        Show
                      </a>
                    </span>
                  </div>
                }

                {is_multi_form_report &&
                  !is_form_report &&
                  project_task.access.can_create_form_report && (
                    <div>
                      <p className="text-muted mb-0">
                        {project_task.multi_form_reports_count} received{" "}
                      </p>
                      <a
                        href="#"
                        className="h6"
                        onClick={this.props.generateNewReport}
                      >
                        <i className="fa fa-plus"></i> Fillout new form
                      </a>
                    </div>
                  )}

                <div className="mt-4">
                  <Stickers
                    resource_type="project_tasks"
                    resource_id={project_task.id}
                  />
                </div>
              </div>

              <div className="col-md-5">
                <div className="project-status pull-right">
                  <div>
                    <i
                      className="fa fa-square"
                      style={{
                        color: project_task.state.color,
                        fontSize: "20px",
                      }}
                    ></i>{" "}
                    <h5 style={{ display: "inline-block" }}>
                      {project_task.state.label || "Without status"}
                    </h5>
                    {this.props.user.use_task_relationships ? (
                      <Fragment>
                        {project_task.access.can_edit &&
                          this.props.user.support && (
                            <a
                              href="#"
                              className="pl-3 d-print-none"
                              onClick={() =>
                                this.setState({ open_edit_modal: true })
                              }
                            >
                              Edit
                            </a>
                          )}
                      </Fragment>
                    ) : (
                      <Fragment>
                        {project_task.access.can_edit && (
                          <a
                            href="#"
                            className="pl-3 d-print-none"
                            onClick={() =>
                              this.setState({ open_edit_modal: true })
                            }
                          >
                            Edit
                          </a>
                        )}
                      </Fragment>
                    )}
                  </div>

                  <h6>
                    <small className="text-muted text-uppercase">
                      Assigned to
                    </small>{" "}
                    {assignedTo(project_task, this.props.user) ||
                      "Not assigned"}
                  </h6>
                  {project_task.scheduled_at_label && is_schedulable && (
                    <small>
                      <p className="mb-0">
                        <span className="text-muted text-uppercase">
                          <i className="fa fa-calendar"></i> Scheduled:
                        </span>{" "}
                        {project_task.scheduled_at_label}
                      </p>
                    </small>
                  )}
                  <h6>
                    <small>
                      <span className="text-muted text-muted text-uppercase">
                        Last Updated:{" "}
                      </span>{" "}
                      {project_task.updated_at_label}
                    </small>
                  </h6>

                  {project_task.has_scheduling_calendar && (
                    <small className="d-print-none mr-3">
                      <i className="fa fa-calendar mr-1 text-muted font-weight-light"></i>
                      <a
                        className="text-info"
                        href="#"
                        onClick={() =>
                          this.setState({
                            open_schedule_calendar:
                              !this.state.open_schedule_calendar,
                          })
                        }
                      >
                        Schedule
                      </a>
                    </small>
                  )}

                  {project_task.access.can_view_details && (
                    <small className="d-print-none">
                      <i className="fa fa-expand mr-1 text-muted font-weight-light"></i>
                      <a
                        className="text-info"
                        href="#"
                        onClick={() =>
                          this.setState({
                            open_details_modal: !this.state.open_details_modal,
                          })
                        }
                      >
                        Project Details
                      </a>
                    </small>
                  )}

                  {use_task_relationships && (
                    <Fragment>
                      {!this.props.user.support && (
                        <small className="d-print-none">
                          <i className="fa fa-sitemap mr-1 ml-3 text-muted font-weight-light"></i>
                          <a
                            className="text-info"
                            href="#"
                            onClick={() =>
                              this.setState({
                                open_relationships_modal:
                                  !this.state.open_relationships_modal,
                              })
                            }
                          >
                            Relationships
                          </a>
                        </small>
                      )}
                      {this.props.user.support && (
                        <small className="d-print-none">
                          <i className="fa fa-sitemap mr-1 ml-3 text-muted font-weight-light"></i>
                          <a
                            href="#"
                            className="text-info"
                            onClick={() =>
                              this.setState({
                                open_support_relationships_modal: true,
                              })
                            }
                          >
                            Relationships
                          </a>
                        </small>
                      )}
                    </Fragment>
                  )}

                  {project_task.access.can_view_activity &&
                    <small className="d-print-none d-inline-block">
                      <i className="fa fa-history mr-1 ml-3 text-muted font-weight-light"></i>
                      <a
                        className="text-info"
                        href="#"
                        onClick={() =>
                          this.setState({
                            open_activity_modal: !this.state.open_activity_modal,
                          })
                        }
                      >
                        Activity
                      </a>
                    </small>
                  }
                </div>
              </div>
            </div>

            <div className="row py-2 d-print-none" style={bbStyle}>
              <div className="col-md-12">
                {project_task.access.can_run_action &&
                  filtered_actions.length > 0 && (
                    <div className="dropdown pull-right">
                      <button
                        className="btn btn-outline-primary dropdown-toggle"
                        type="button"
                        id="dropdownMenuButton"
                        data-toggle="dropdown"
                        aria-haspopup="true"
                        aria-expanded="false"
                      >
                        Actions
                      </button>
                      <div
                        className="dropdown-menu dropdown-menu-right"
                        aria-labelledby="dropdownMenuButton"
                      >
                        {filtered_actions.map((action) => {
                          return (
                            <a
                              onClick={() => this.openActionModal(action)}
                              key={action.identifier}
                              className="dropdown-item"
                              href="#"
                            >
                              {action.label}
                            </a>
                          );
                        })}
                      </div>
                    </div>
                  )}
              </div>
            </div>

            {this.state.open_schedule_calendar && (
              <div className="row py-2 mt-3">
                <div className="col-md-12">
                  {this.props.user.sub_organization_user ? (
                    <Fragment>
                      {!project_task.scheduled_at ||
                      project_task.full_scheduled_set_by_sub_org_user ? (
                        <SubOrganizationFullSchedulerContainer
                          project_task_ids={[project_task.id]}
                          comes_from_map={false}
                          returnToMapView={() =>
                            this.setState({ open_schedule_calendar: false })
                          }
                          updateScheduledRecords={this.updateScheduledRecords}
                          user={this.props.user}
                        />
                      ) : (
                        <SubOrganizationSchedulerContainer
                          project_task_ids={[project_task.id]}
                          comes_from_map={false}
                          returnToMapView={() =>
                            this.setState({ open_schedule_calendar: false })
                          }
                          updateScheduledRecords={this.updateScheduledRecords}
                          user={this.props.user}
                        />
                      )}
                    </Fragment>
                  ) : (
                    <SchedulerContainer
                      project_task_ids={[project_task.id]}
                      comes_from_map={false}
                      returnToMapView={() =>
                        this.setState({ open_schedule_calendar: false })
                      }
                      updateScheduledRecords={this.updateScheduledRecords}
                    />
                  )}
                </div>
              </div>
            )}

            {this.state.open_edit_modal && (
              <EditProjectTaskModal
                project_task={project_task}
                is_schedulable={is_schedulable}
                closeModal={() => this.setState({ open_edit_modal: false })}
                is_open={this.state.open_edit_modal}
                updateProjectTask={this.updateProjectTask}
                user={this.props.user}
              />
            )}

            {this.state.open_action_modal && (
              <ActionProjectTaskModal
                project_task={project_task}
                action={this.state.selected_action}
                closeModal={() =>
                  this.setState({
                    open_action_modal: false,
                    selected_action: null,
                  })
                }
                is_open={this.state.open_action_modal}
                updateProjectTask={this.updateProjectTask}
              />
            )}

            {this.state.open_details_modal && (
              <DetailsSidebar
                object={project_task.project}
                resource_type="projects"
                resource_id={project_task.project_id}
                close={() => this.setState({ open_details_modal: false })}
              />
            )}

            {this.state.open_activity_modal && (
              <ActivitySidebar
                resource_type={"project_tasks"}
                resource_id={project_task.id}
                close={() => this.setState({ open_activity_modal: false })}
              />
            )}

            {this.state.open_relationships_modal && (
              <RelationshipsModal
                project_task={project_task}
                closeModal={() =>
                  this.setState({ open_relationships_modal: false })
                }
              />
            )}

            {this.state.open_support_relationships_modal && (
              <SupportRelationshipsProjectTaskBatchModalWithSync
                closeModal={() =>
                  this.setState({ open_support_relationships_modal: false })
                }
                selected_ids={[project_task.id]}
                user={this.props.user}
              />
            )}

            {this.state.open_pending_submission_modal && (
              <PendingSubmissionModal
                pending_submission_id={project_task.pending_submission_id}
                project_task={project_task}
                close={() =>
                  this.setState({ open_pending_submission_modal: false })
                }
              />
            )}
          </div>
        )}
      </Fragment>
    );
  }
}
