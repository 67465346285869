import React, { Component, useState, useEffect, useContext } from 'react'
import { render } from 'react-dom'

import { OrganizationContext } from '../../../contexts/OrganizationContext'

import L from 'leaflet';
import "leaflet-lasso";
import "leaflet.markercluster";

const defaultMarkerColor = "#000000"

const activeIcon = (record) => {
  let markerHtmlStyles = `
    background-color: ${record.state.color || defaultMarkerColor}; 
    width: 2rem;
    height: 2rem;
    display: block;
    left: -0.7rem;
    top: -1.35rem;
    position: relative;
    border-radius: 2rem 2rem 0;
    transform: rotate(45deg);
    border: 4px dotted white
  `

  let icon = new L.divIcon({
    html: `<span style="${markerHtmlStyles}"/>`
  });

  return(icon)
}

const defaultIcon = (record) => {
  let markerHtmlStyles = `
    background-color: ${record.state.color || defaultMarkerColor}; 
    width: 2rem;
    height: 2rem;
    display: block;
    left: -0.7rem;
    top: -1.35rem;
    position: relative;
    border-radius: 2rem 2rem 0;
    transform: rotate(45deg);
    border: 1px solid #FFFFFF
  `

  let icon = new L.divIcon({
    html: `<span style="${markerHtmlStyles}"/>`
  });

  return(icon)
}

const MapComponent = (props) => {
  const [map, setMap] = useState(undefined)

  let context =  useContext(OrganizationContext)

  const setSelectedLayers = (layers) => {
    if(props.active_job_id) {
      return
    }

    let selected_ids = [...props.selected_ids]

    layers.forEach(layer => {
      if (layer instanceof L.Marker && !props.selected_ids.includes(layer.options.record_id)) {
        let record = props.records.find(r => r.id == layer.options.record_id)
        if(!record) {
          return
        }

        layer.setIcon(activeIcon(record));
        selected_ids.push(layer.options.record_id)
      }
    });

    props.setSelectedIds(selected_ids)
  }

  const initializeMarkers = (created_map) => {
    let record_ids = []
    let markers = props.records.map(record => { 
      record_ids.push(record.id)

      let marker = L.marker([record.project.latitude, record.project.longitude], {icon: defaultIcon(record), record_id: record.id, name: record.name, type: record.type})
      if(!props.selected_ids.includes(record.id)) {
        marker.setIcon(defaultIcon(record));
      } else {
        marker.setIcon(activeIcon(record));
      }
      return marker
    })

    const markerClusterGroup = L.markerClusterGroup({ showCoverageOnHover: true, disableClusteringAtZoom: 15, isCluster: true });
    markerClusterGroup.addLayers(markers);

    const layers = [
      markerClusterGroup,
    ];
    const featureGroup = L.featureGroup(layers).addTo(created_map);
    created_map.fitBounds(
      featureGroup.getBounds(), {
        padding: [70, 70],
        animate: false
      }
    );

    created_map.removeEventListener('lasso.finished')
    created_map.on('lasso.finished', event => {
      setSelectedLayers(event.layers);
    })

    //Helps set selected_ids after update on queue
    let new_selected_ids = props.selected_ids.filter(e => record_ids.includes(e))
    if(new_selected_ids.length != props.selected_ids.length) {
      props.setSelectedIds(new_selected_ids)
    }
  }

  useEffect(() => {
    let map = L.map('projects-map').setView([0, 0], 0);
    L.tileLayer('https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png', {
      attribution: '&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
    }).addTo(map);
    const lassoControl = L.control.lasso().addTo(map)
    setMap(map)
    initializeMarkers(map)
  }, [])

  useEffect(() => {
    if(map) {
      //Always update event to not use initial props
      map.removeEventListener('lasso.finished')
      map.on('lasso.finished', event => {
        setSelectedLayers(event.layers);
      })

      map.eachLayer((layer) => {
        if (layer instanceof L.Marker) {
          let record = props.records.find(r => r.id == layer.options.record_id)
          if(!record) {
            return
          }

          if(!props.selected_ids.includes(layer.options.record_id)) {
            layer.setIcon(defaultIcon(record));
          } else {
            layer.setIcon(activeIcon(record));
          }
        }
      });
    }
  }, [props.selected_ids, props.active_job_id])

  useEffect(() => {
    if(map) {
      //Need to remove cluster layer and recreate, not just markers
      map.eachLayer((layer) => {
        if(layer.options.isCluster) {
          map.removeLayer(layer)
        }
      })

      initializeMarkers(map)
    }
  }, [props.records])

  return(
    <div id="projects-map">
    </div>
  )
}

export default MapComponent
