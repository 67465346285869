import React from "react";
import StateLabel from "./StateLabel";
import RightArrowSvg from "./right_arrow.svg";

const ProjectTasksStateChanged = ({entry, from_group}) => {
  let { data } = entry
  let { new_state, past_state } = data

  return (
    <li className="timeline-item mb-4">
    
      <h6 class="small"> status changed</h6>

      <div>
        {past_state.label ? (
          <StateLabel bgColor={past_state.color}>{past_state.label}</StateLabel>
        ) : (
          <StateLabel bgColor={"#000000"}>EMPTY STATE</StateLabel>
        )}
       
        <img src={RightArrowSvg} />

        {new_state.label ? (
          <StateLabel bgColor={new_state.color}>{new_state.label}</StateLabel>
        ) : (
          <StateLabel bgColor={"#000000"}>EMPTY STATE</StateLabel>
        )}
      </div>

      {!from_group && (
        <small className="text-muted mb-1 fw-bold">
        {entry.current_user.name} · {entry.created_at_label}
        </small>
      )}
    </li>
  );
}

export default ProjectTasksStateChanged
