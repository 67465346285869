import React, { Component, Fragment } from 'react'
import { render } from 'react-dom'

import Modal from 'react-modal';
import { SideBarLoader } from '../helpers/Loaders'

import axios from 'axios'
import ActivityEntry from './ActivityEntry';

const customStyles = {
  overlay : {
    position          : 'fixed',
    top               : 0,
    left              : '60%',
    right             : 0,
    bottom            : 0,
    backgroundColor   : 'rgba(0, 0, 0, 0.60)',
    zIndex            : 101
  },
  content : {
    position                   : 'absolute',
    top                        : '0px',
    left                       : '0%',
    right                      : '0%',
    bottom                     : '0px',
    border                     : '1px solid #ccc',
    background                 : '#fff',
    overflow                   : 'auto',
    WebkitOverflowScrolling    : 'touch',
    borderRadius               : '0px',
    outline                    : 'none',
    padding                    : '20px'
  }
}

const mobileCustomStyles = {
  overlay : {
    position          : 'fixed',
    top               : 0,
    left              : 0,
    right             : 0,
    bottom            : 0,
    backgroundColor   : 'rgba(0, 0, 0, 0.60)',
    zIndex            : 101
  },
  content : {
    position                   : 'absolute',
    top                        : '0px',
    left                       : '0%',
    right                      : '0%',
    bottom                     : '0px',
    border                     : '1px solid #ccc',
    background                 : '#fff',
    overflow                   : 'auto',
    WebkitOverflowScrolling    : 'touch',
    borderRadius               : '0px',
    outline                    : 'none',
    padding                    : '20px'
  }
}

function groupByRequestId(arr) {
  return arr.reduce((acc, item) => {
      let key = item.request_id !== null ? item.request_id : item.id;
      if (!acc.has(key)) {
          acc.set(key, []);
      }
      acc.get(key).push(item);
      return acc;
  }, new Map());
}

export default class ActivitySidebar extends Component {
  constructor(props) {
    super(props)
    this.state = {
      loading: true,
      data: null,
      isMobile: false,
      request_data: [],
    }

    this.loadResource = this.loadResource.bind(this)
    this.escFunction = this.escFunction.bind(this)
    this.checkIfMobile = this.checkIfMobile.bind(this)
  }

  checkIfMobile = () => {
    const isMobile = window.innerWidth <= 768; // Adjust this threshold based on your needs
    if (isMobile !== this.state.isMobile) {
      this.setState({ isMobile });
    }
  };

  loadResource() {
    let {resource_type, resource_id} = this.props
    let url = `/web_api/projects_management/activity_entries`
    axios
      .get(url, { params: { resource_type, resource_id } })
      .then((response) => {
        let data = response.data.data.map((e) => e.attributes )
        let grouped = groupByRequestId(data);
        let groupedArray = [...grouped.values()];

        this.setState({ data, loading: false, request_data: groupedArray });
      });
  }

  escFunction(e){
    if(e.keyCode === 27) {
      this.props.close()
    }
  }

  componentDidMount(){
    document.addEventListener("keydown", this.escFunction, false);
    this.loadResource()
    this.checkIfMobile(); // Check initial window size
    window.addEventListener('resize', this.checkIfMobile);
  }

  componentWillUnmount(){
    document.removeEventListener("keydown", this.escFunction, false);
    window.removeEventListener('resize', this.checkIfMobile);
  }

  componentDidUpdate(prevProps) {
    if(prevProps.resource_id != this.props.resource_id) {
      this.setState({data: null, loading: true}, () => {
        this.loadResource()
      })
    }
  }

  render() {
    let { data, request_data } = this.state
    return (
      <Fragment>
        <Modal style={this.state.isMobile ? mobileCustomStyles : customStyles} ariaHideApp={false} isOpen={true}>
          <div>
            <div>
              <h4 className="d-inline-block">
                <i className='fa fa-history mr-1'></i> Activity
              </h4>
              <button
                type="button"
                onClick={this.props.close}
                className="close"
                aria-label="Close"
              >
                <span aria-hidden="true" style={{ fontSize: "28px" }}>
                  <i className='fa fa-times'></i>
                </span>
              </button>
              <hr />
            </div>

            <div>
              {this.state.loading ? (
                <SideBarLoader />
              ) : (
                <div className="campo__details">
                  <section className="w-100 px-2 pt-4">
                    { request_data.length == 0 &&
                      <div className='text-center text-muted'>
                      No events registered yet.
                      </div>
                    }
                    <ul className="timeline-basic-example">
                      {request_data.map((group, v) => {
                        return (
                          <ActivityEntry group={group} key={v}/>
                        );
                      })}
                    </ul>
                  </section>
                </div>
              )}
            </div>
          </div>
        </Modal>
      </Fragment>
    );
  }
}
