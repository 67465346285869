import { useQuery, useMutation, useQueryClient } from '@tanstack/react-query'
import axios from '../../../../setup/axiosConfig'

const deleteAttachment = (id) => {
  return axios
    .delete(`/web_api/projects_management/attachments/${id}`)
    .then((response) => response.data);
};

export const useDeleteAttachment = ({ resource_type, resource_id }) => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: deleteAttachment,
    onSuccess: () => {
      queryClient.invalidateQueries([`attachments-${resource_type}`, resource_id]);
    }
  });
};

const updateAttachment = (data) => {
  return axios
    .put(`/web_api/projects_management/attachments/${data.id}`, data)
    .then((response) => response.data);
};

export const useUpdateAttachment = ({ resource_type, resource_id }) => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: updateAttachment,
    onSuccess: () => {
      queryClient.invalidateQueries([`attachments-${resource_type}`, resource_id]);
    }
  });
};

const getSignedUrls = (params) => {
  return axios
    .get(`/web_api/projects_management/attachments/signed_urls`, { params })
    .then((response) => response.data);
};

export const useGetSignedUrls = () => {
  return useMutation({
    mutationFn: getSignedUrls
  });
};

const getAttachments = (params) => {
  return axios.get('/web_api/projects_management/attachments', { params }).then(res => {
    return res.data.data.map(e => e.attributes)
  })
}

export const useGetAttachments = ({ resource_type, resource_id }) => {
  return useQuery({
    queryKey: [`attachments-${resource_type}`, resource_id],
    queryFn: () => getAttachments({ resource_type, resource_id })
  });
};
