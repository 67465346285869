import React, { Component, Fragment } from 'react'
import CurrentTab from './CurrentTab'
import PreviousTab from './PreviousTab'
import Comments from '../../helpers/Comments'

export default class Tabs extends Component {
  constructor(props) {
    super(props)
    this.state = {
      active_tab: 'current'
    }

    this.handleTab = this.handleTab.bind(this)
    this.currentTab = this.currentTab.bind(this)
  }

  handleTab(tab) {
    this.setState({active_tab: tab})
  }

  currentTab() {
    let tab = (<div></div>)
    if(this.state.active_tab == 'current') {
      tab = (
        <CurrentTab
          project_task={this.props.project_task}
          moveToFormPage={this.props.moveToFormPage}
          generateNewReport={this.props.generateNewReport}
          user={this.props.user}
        />
      )
    } else if(this.state.active_tab == 'comments') {
      tab = (
        <div className='my-2'>
          <Comments
            resource_type="project_tasks"
            resource_id={this.props.project_task.id}
            reload_comments={this.props.reload_comments}
          />
        </div>
      )
    } else if(this.state.active_tab == 'previous') {
      tab = (
        <PreviousTab
          project_task={this.props.project_task}
          moveToFormPage={this.props.moveToFormPage}
          user={this.props.user}
        />
      )
    }
    return tab
  }

  render() {
    let { active_tab } = this.state

    return(
      <Fragment>
        <div className='bg-white px-4 py-3'>
          <div className='row'>
            <div className='col-md-12'>
              <ul className="nav nav-tabs pm-tabs">
                <li className="nav-item" onClick={() => this.handleTab('current')}>
                  <a className={"nav-link " + (active_tab == 'current' ? 'active' : '')}>
                    Current
                  </a>
                </li>

                <li className="nav-item" onClick={() => this.handleTab('previous')}>
                  <a className={"nav-link " + (active_tab == 'previous' ? 'active' : '')}>
                    Previous
                  </a>
                </li>
                <li className="nav-item" onClick={() => this.handleTab('comments')}>
                  <a className={"nav-link " + (active_tab == 'comments' ? 'active' : '')}>
                    Comments
                  </a>
                </li>
              </ul>
            </div>
          </div>

          <div className='row mt-2'>
            <div className='col-md-12'>
              {this.currentTab()}
            </div>
          </div>
        </div>
      </Fragment>
    )
  }
}
