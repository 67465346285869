import { useQuery } from '@tanstack/react-query'
import axios from '../../../../setup/axiosConfig'

const getStickers = (params) => {
  return axios.get('/web_api/projects_management/stickers', { params: params }).then(res => {
    return res.data.data.map(e => e.attributes)
  })
}

export const useGetStickers = (params) => {
  return useQuery({
    queryKey: ['stickers', params],
    queryFn: () => getStickers(params)
  });
}
