var ReactRailsUJS = require('react_ujs');
ReactRailsUJS.useContext({
  PmAnalytics: require('./components/containers/PmAnalytics'),
  PmDocumentGroups: require('./components/containers/PmDocumentGroups'),
  PmInventoryContainer: require('./components/containers/PmInventoryContainer'),
  PmProjectBoardContainer: require('./components/containers/PmProjectBoardContainer'),
  PmProjectBoardsContainer: require('./components/containers/PmProjectBoardsContainer'),
  PmProjectImportsContainer: require('./components/containers/PmProjectImportsContainer'),
  PmProjectReportsContainer: require('./components/containers/PmProjectReportsContainer'),
  PmProjectsContainer: require('./components/containers/PmProjectsContainer'),
  PmSearchResources: require('./components/containers/PmSearchResources'),
  PmSubOrganizationsContainer: require('./components/containers/PmSubOrganizationsContainer'),
  PmUsersContainer: require('./components/containers/PmUsersContainer'),
  PmTimesheetContainer: require('./components/containers/PmTimesheetContainer'),
  ProjectImagesZip: require('./components/containers/ProjectImagesZip'),
  SchemaEditor: require('./components/containers/SchemaEditor'),
  FormDesigner: require('./components/containers/FormDesigner'),
});
