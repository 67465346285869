import React from 'react'
import { useGetStickers } from './hooks/usetStickers'

const Stickers = ({resource_type, resource_id}) => {
  const { data: stickers, isPending } = useGetStickers({resource_type, resource_id})
  if(!isPending && (stickers || []).length == 0) {
    return(<></>)
  }

  return (
    <div className="row">
      { (stickers || []).map((sticker, index) => {
        return(
          <div className="col-4 mb-2 border" key={index}>
            <div>
              <h6 className="mb-0 mr-1 d-inline-block text-uppercase" style={{ color: `${sticker.sticker_type.color}` }}>
                {sticker.sticker_type.name}
                </h6>
              { sticker.value &&
                <h6 className="mb-0 d-inline-block text-muted">
                  ({sticker.value})
                </h6>
              }
            </div>
            <small className="text-muted">{sticker.date}</small>
          </div>
        )

      })}
    </div>
  )
}

export default Stickers
