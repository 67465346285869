import React from "react";

const ProjectTasksWorkflowApplied = ({entry, from_group}) => {
  let { data } = entry
  let { workflow, trigger_project_task } = data

  return (
    <li className="timeline-item mb-4">
      <h6 className="mb-1">
        {workflow} <span className="small">workflow applied on task</span> {trigger_project_task}
      </h6>

      {!from_group && (
        <small className="text-muted mb-1 fw-bold">
          {entry.current_user.name} · {entry.created_at_label}
        </small>
      )}
    </li>
  );
}

export default ProjectTasksWorkflowApplied
