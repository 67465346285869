[
  {
    "label": "Calculated Project Fields",
    "link": "/admin/calculated_project_fields"
  },
  {
    "label": "Data Dispenser",
    "link": "/admin/data_dispensers"
  },
  {
    "label": "Dynamic Lists",
    "link": "/admin/dynamic_lists"
  },
  {
    "label": "Event Logs",
    "link": "/admin/event_logs"
  },
  {
    "label": "Forms",
    "link": "/admin/forms"
  },
  {
    "label": "Form Design Updators",
    "link": "/admin/form_design_updators"
  },
  {
    "label": "Formula Tester",
    "link": "/projects_management/test_formula"
  },
  {
    "label": "Organization Config",
    "link": "/admin/organization_configs"
  },
  {
    "label": "Project Boards",
    "link": "/admin/project_boards"
  },
  {
    "label": "Project Dashboards",
    "link": "/admin/project_dashboards"
  },
  {
    "label": "Project Event Types",
    "link": "/admin/project_event_types"
  },
  {
    "label": "Project Field Groups",
    "link": "/admin/project_field_groups"
  },
  {
    "label": "Project Group Types",
    "link": "/admin/project_group_types"
  },
  {
    "label": "Project Import Types",
    "link": "/admin/project_import_types"
  },
  {
    "label": "Project Imports",
    "link": "/admin/project_imports"
  },
  {
    "label": "Project Invoices",
    "link": "/admin/project_invoices"
  },
  {
    "label": "Project Invoice Line Item Details",
    "link": "/admin/project_invoice_line_item_details"
  },
  {
    "label": "Project Invoice Item Backcharge Details",
    "link": "/admin/project_invoice_item_backcharge_details"
  },
  {
    "label": "Project Invoice Templates",
    "link": "/admin/project_invoice_templates"
  },
  {
    "label": "Project Management States",
    "link": "/admin/project_management_states"
  },
  {
    "label": "Project Queues",
    "link": "/admin/project_queues"
  },
  {
    "label": "Project Relationship Configs",
    "link": "/admin/project_relationship_configs"
  },
  {
    "label": "Project Relationship Types",
    "link": "/admin/project_relationship_types"
  },
  {
    "label": "Project Reports",
    "link": "/admin/project_reports"
  },
  {
    "label": "Project Report Types",
    "link": "/admin/project_report_types"
  },
  {
    "label": "Project Templates",
    "link": "/admin/project_templates"
  },
  {
    "label": "Project Task Attachments",
    "link": "/admin/project_task_attachments"
  },
  {
    "label": "Project Task Classifiers",
    "link": "/admin/project_task_classifiers"
  },
  {
    "label": "Project Task File Templates",
    "link": "/admin/project_task_file_templates"
  },
  {
    "label": "Project Task Finders",
    "link": "/admin/project_task_finders"
  },
  {
    "label": "Project Task Importers",
    "link": "/admin/project_task_importers"
  },
  {
    "label": "Project Task Order Report Templates",
    "link": "/admin/project_task_order_report_templates"
  },
  {
    "label": "Project Task Relationship Types",
    "link": "/admin/project_task_relationship_types"
  },
  {
    "label": "Project Task Type Config",
    "link": "/admin/project_task_type_configs"
  },
  {
    "label": "Project Task Types",
    "link": "/admin/project_task_types"
  },
  {
    "label": "Project Tasks",
    "link": "/admin/project_tasks"
  },
  {
    "label": "Project Template Changes",
    "link": "/admin/project_template_changes"
  },
  {
    "label": "Project Timer Types",
    "link": "/admin/project_timer_types"
  },
  {
    "label": "Project Timers",
    "link": "/admin/project_timers"
  },
  {
    "label": "Project Workflow Triggers",
    "link": "/admin/project_workflow_triggers"
  },
  {
    "label": "Project Workflows",
    "link": "/admin/project_workflows"
  },
  {
    "label": "Project Task Sticker Types",
    "link": "/admin/project_task_sticker_types"
  },
  {
    "label": "Project Template Exec Records",
    "link": "/admin/project_template exec records"
  },
  {
    "label": "Project Template Groups",
    "link": "/admin/project_template_groups"
  },
  {
    "label": "Projects",
    "link": "/admin/projects"
  },
  {
    "label": "Reference Data Types",
    "link": "/admin/reference_data_types"
  },
  {
    "label": "Reference Data",
    "link": "/admin/reference_data"
  },
  {
    "label": "Sub Organization Groups",
    "link": "/admin/sub_organization_groups"
  },
  {
    "label": "Sub Organization Price schedules",
    "link": "/admin/sub_organization_price_schedules"
  },
  {
    "label": "Sub Organizations",
    "link": "/admin/sub_organizations"
  },
  {
    "label": "Submissions",
    "link": "/admin/submissions"
  },
  {
    "label": "User Project Task Relationships",
    "link": "/admin/user_project_task_relationships"
  },
  {
    "label": "Users",
    "link": "/admin/users"
  }
 ]