import React from "react";

const CommentsWritten = ({entry, from_group}) => {
  let { data, current_user } = entry
  let { comes_from_system } = data

  return (
    <li className="timeline-item mb-4">
      {!comes_from_system ? (
        <h6 className="mb-1">
          A system comment <span className="text-muted"> has been written</span>
        </h6>
      ) : (
        <h6 className="mb-1">
          {from_group ? "" : current_user.name}{" "}
          <span className="text-muted"> has written a comment</span>
        </h6>
      )}

      {!from_group && (
        <small className="text-muted mb-1 fw-bold">
          {entry.created_at_label}
        </small>
      )}
    </li>
  );
}

export default CommentsWritten
