import React from "react";

const ProjectTasksEmailNotificationSent = ({entry, from_group}) => {
  let { data } = entry
  let { emails_count} = data

  return (
    <li className="timeline-item mb-4">
      <h6 className="mb-1">
        <span className="small">{emails_count} {emails_count == 1 ? 'email' : 'emails'} sent</span>
      </h6>

      {!from_group && (
        <small className="text-muted mb-1 fw-bold">
          {entry.current_user.name} · {entry.created_at_label}
        </small>
      )}
    </li>
  );
}

export default ProjectTasksEmailNotificationSent
