import React, { Component, Fragment } from 'react'
import { render } from 'react-dom'

import Modal from 'react-bootstrap/Modal';

import api from '../../../utils/pm_project_tasks_api'

import Select from 'react-select'
import axios from 'axios'

export default class ActionProjectTaskModal extends Component {
  constructor(props) {
    super(props)

    this.state = {
      loading: true,
      model : {
        comment: '',
      }
    }

    this.handleSave = this.handleSave.bind(this)
    this.handleValue = this.handleValue.bind(this)
    this.handleClose = this.handleClose.bind(this)

  }

  handleValue(type, e) {
    let model = this.state.model
    if(type == 'comment') {
      model[type] = e.target.value
    }
    this.setState({model})
  }

  handleClose() {
    this.setState({
      model: {
      },
    }, () => {
      this.props.closeModal()
    })
  }

  handleSave() {
    api.processAction(this.props.project_task.id, this.props.action.identifier, this.state.model.comment).then((response) => {
      this.props.updateProjectTask(response.data.attributes)
    })
  }

  render() {
    let { project_task, action } = this.props
    let { model } = this.state
    let { label } = action

    return(
      <Modal show={this.props.is_open} onHide={this.handleClose} animation={true}>
        <Modal.Body>
          <div className='p-4'>
            <h3>{label}</h3>

            <p className="pt-0">Please confirm action and add comments below.</p>

            <div className="form-group pt-2">
              <label>Comment</label>
              <textarea value={model.comment} className='form-control' onChange={(e) => this.handleValue('comment', e)} >
              </textarea>
            </div>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <button onClick={this.handleClose} className="btn btn-light">Cancel</button>
          <button className="btn btn-primary" onClick={this.handleSave}>{label}</button>
        </Modal.Footer>
      </Modal>
    )
  } 
}
