import React, { Component, Fragment } from 'react'
import Modal from 'react-bootstrap/Modal'
import _ from 'lodash'

import UserForm from './UserForm'

const Errors = (props) => {
  return(
    <div className="alert alert-danger" role="alert">
      <ul style={{marginBottom: '0px'}}>
        {props.errors.map((error, index) => {
          return(
            <li key={index}>{error}</li>
          )
        })}
      </ul>
    </div>
  )
}

export default class UserModal extends Component {
  constructor(props) {
    super(props)

    let model = props.model || {
      name: '',
      email: '',
      phone: '',
      principal_role: null,
      password: '',
      should_change_password_on_login: true,
      has_financial_access: false,
      has_external_analysis_access: false,
      has_timesheet: false,
      default_work_type: null,
      user_configuration_template: {value: 'none', label: "None"}
    }

    this.state = {
      loading: false,
      model: model,
      datasources: props.datasources,
      invalid_fields: [],
    }

    this.handleSave = this.handleSave.bind(this)
    this.handleClose = this.handleClose.bind(this)
    this.updateModel = this.updateModel.bind(this)
  }

  handleClose() {
    this.setState({
      model: {
        name: '',
        email: '',
        phone: '',
        principal_role: null,
        password: '',
        should_change_password_on_login: true,
        has_financial_access: false,
        has_timesheet: false,
        default_work_type: null,
        user_configuration_template: {value: 'none', label: "None"}
      },
      edited_names: [],
    }, () => {
      this.props.closeModal()
    })
  }

  updateModel(model) {
    this.setState({model})
  }

  handleSave() {
    let invalid_fields = []
    let required_fields = ['name', 'email', 'principal_role']
    if(this.state.model.user_configuration_template.value != 'none') {
      required_fields = ['name', 'email']
    }

    required_fields.forEach((field) => {
      if(!this.state.model[field]) {
        invalid_fields.push(field)
      } else {
        if(field == 'pricipal_role' && !this.state.model[field].value) {
          invalid_fields.push(field)
        }
      }
    })

    if(invalid_fields.length > 0) {
      this.setState({invalid_fields})

    } else {
      this.props.handleSave(this.state.model)
    }
  }

  render() {
    let show_password_message = this.state.model.id ? true : false
    return(
      <Modal show={this.props.is_open} onHide={this.handleClose} animation={true} size="xl">
        <Modal.Header closeButton>
          <Modal.Title>
            {this.props.title || ''}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          { this.props.errors &&
            <div className='row'>
              <div className='col-md-12'>
                <Errors
                  errors={this.props.errors}
                />
              </div>
             </div>
          }
          { !this.state.loading &&
            <div className='row'>
              <div className='col-md-12'>
                <UserForm
                  model={this.state.model}
                  datasources={this.state.datasources}
                  updateModel={this.updateModel}
                  show_password_message={show_password_message}
                  invalid_fields={this.state.invalid_fields}
                  user={this.props.user}
                />
              </div>
            </div>
          }
        </Modal.Body>

        <Modal.Footer>
          <button onClick={this.handleClose} className="btn btn-secondary">Close</button>
          <button className="btn btn-primary" onClick={this.handleSave}>Save changes</button>
        </Modal.Footer>
      </Modal>
    )
  }
}
