import React from "react";

const ProjectTasksActionApplied = ({entry, from_group}) => {
  let { data } = entry
  let { action, comment } = data

  return (
    <li className="timeline-item mb-4">
      <h6 className="mb-1">
      {action} <span className="small">action performed</span>
      </h6>

     {comment && (
       <div
         className="px-3 py-1 mb-1"
         style={{ backgroundColor: "#e6ecf1", borderRadius: "5px" }}
       >
         <small>{comment}</small>
       </div>
     )}

      {!from_group && (
        <small className="text-muted mb-1 fw-bold">
          {entry.current_user.name} · {entry.created_at_label}
        </small>
      )}
    </li>
  );
}

export default ProjectTasksActionApplied
