import React, { Component, Fragment } from 'react'
import { render } from 'react-dom'

import api from '../../../utils/comments_api'
import moment from 'moment'

export default class Comments extends Component {
  constructor(props) {
    super(props)
    this.state = {
    }

    this.loadComments = this.loadComments.bind(this)
  }

  loadComments() {
    let container = `comments-${this.props.resource_type}`
    let resource_data = {
      resource_type: this.props.resource_type,
      resource_id: this.props.resource_id,
    }

    $(`#${container}`).comments({
      enableEditing: true,
      enableUpvoting: false,
      enableDeleting: true,
      enableAttachments: false,
      maxRepliesVisible: 10,
      enableNavigation: false,
      timeFormatter: function(time) {
        return moment(time).calendar();
      },
      getComments: function(success, error) {
        api.getComments(resource_data).then((response) => {
          success(response.comments)
        })
      },
      postComment: function(commentJSON, success, error) {
        let data = {...resource_data, comment: commentJSON}
        api.createComment(data).then((response) => {
          success(response)
        })
      },
      putComment: function(commentJSON, success, error) {
        let data = {...resource_data, comment: commentJSON}
        api.updateComment(data, commentJSON.id).then((response) => {
          success(response)
        })
      },
      deleteComment: function(commentJSON, success, error) {
        let data = {...resource_data, comment: commentJSON}
        api.destroyComment(data, commentJSON.id).then((response) => {
          success(response)
        })
      }
    })
  }

  componentDidUpdate(prevProps) {
    if(prevProps.resource_id != this.props.resource_id) {
      this.loadComments()
    }

    if(prevProps.reload_comments != this.props.reload_comments) {
      this.loadComments()
    }
  }

  componentDidMount() {
    this.loadComments()
  }

  render() {
    let container = `comments-${this.props.resource_type}`
    return(
      <div id={container}>
      </div>
    )
  }
}


