import React from "react";
import RightArrowSvg from "./right_arrow.svg";

const ProjectTasksUserAssigned = ({entry, from_group}) => {
  let { data } = entry
  let { new_user, past_user } = data

  return (
    <li className="timeline-item mb-4">
      <h6 className="mb-1 small">
        user assigment updated 
      </h6>

      <div>
        {past_user}
        <img src={RightArrowSvg} />
        {new_user}
      </div>

      {!from_group && (
        <small className="text-muted mb-1 fw-bold">
         {entry.current_user.name} · {entry.created_at_label}
        </small>
      )}
    </li>
  );
}

export default ProjectTasksUserAssigned
