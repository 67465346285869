import React, { Component, Fragment } from 'react'
import { render } from 'react-dom'
import ExportTableButton from '../helpers/ExportTableButton'

export default class TableHeader extends Component {
  render() {
    let {
      loading,
      export_data,
      navigateToMap,
      current_user,
      can_edit_on_index,
      showDisable,
      selection_count,
    } = this.props;
    let { headers, export_filename, export_rows} = (export_data || {})

    let edit_buttons = (
      <Fragment>
      </Fragment>
    )

    if(!can_edit_on_index) {
      return(
        <Fragment>
          { headers && export_rows &&
            <div className='d-inline-block'>
              <ExportTableButton
                export_rows={export_rows}
                headers={headers}
                filename={export_filename}
                loading={loading}
              />
            </div>
          }
        </Fragment>
      )
    }

    return(
      <Fragment>
        { showDisable &&
          <button onClick={this.props.onDisable} className="btn btn-sm mr-3 btn-primary">
            Disable {selection_count} user(s)
          </button>
        }

        { this.props.allow_new &&
          <button onClick={this.props.onNew} className="btn btn-sm mr-3 btn-outline-primary">
            <i className='fa fa-plus'></i> New
          </button>
        }

        { current_user ? (
          <Fragment>
            { current_user.use_task_relationships ? (
              <Fragment>
                { current_user.support ? (
                  <Fragment>
                    { !this.props.disabledEdit && (
                      <Fragment>
                        <button onClick={this.props.onEditSupportRelationships} className="btn btn-sm mr-3 btn-primary" disabled={this.props.disabledEdit}>
                        Edit {this.props.selectedItemCount} { this.props.selectedItemCount == 1 ? this.props.selectedItemSingularLabel : this.props.selectedItemPluralLabel } relationships
                        </button>
                        <button onClick={this.props.onEdit} className="btn btn-sm mr-3 btn-secondary" disabled={this.props.disabledEdit}>
                        Edit {this.props.selectedItemCount} { this.props.selectedItemCount == 1 ? this.props.selectedItemSingularLabel : this.props.selectedItemPluralLabel }
                        </button>

                      </Fragment>
                    )}
                  </Fragment>
                ) : (
                  <Fragment>
                    { !this.props.disabledEdit &&
                      <button onClick={this.props.onEditRelationships} className="btn btn-sm mr-3 btn-primary" disabled={this.props.disabledEdit}>
                      Edit {this.props.selectedItemCount} { this.props.selectedItemCount == 1 ? this.props.selectedItemSingularLabel : this.props.selectedItemPluralLabel } relationships
                      </button>
                    }
                  </Fragment>
                )}
              </Fragment>
            ) : (
              <Fragment>
                { !this.props.disabledEdit && <button onClick={this.props.onEdit} className="btn btn-sm mr-3 btn-primary" disabled={this.props.disabledEdit}>
                  Edit {this.props.selectedItemCount} { this.props.selectedItemCount == 1 ? this.props.selectedItemSingularLabel : this.props.selectedItemPluralLabel }
                </button>
                }
              </Fragment>
            )}
          </Fragment>
        ): (
          <Fragment>
            { !this.props.disabledEdit && <button onClick={this.props.onEdit} className="btn btn-sm mr-3 btn-primary" disabled={this.props.disabledEdit}>
              Edit {this.props.selectedItemCount} { this.props.selectedItemCount == 1 ? this.props.selectedItemSingularLabel : this.props.selectedItemPluralLabel }
            </button>
            }
          </Fragment>
        )}

        { headers && export_rows &&
          <div className='d-inline-block'>
            <ExportTableButton
              export_rows={export_rows}
              headers={headers}
              filename={export_filename}
              loading={loading}
            />
          </div>
        }

        { navigateToMap &&
          <div className='ml-4 mt-2 text-muted small d-inline-block pull-right cursor-pointer' onClick={navigateToMap}>
            <i className="fa fa-map"></i> View On Map
          </div>
        }

      </Fragment>
    )
  }
}
