import React from "react";

const ProjectTasksCreated = ({entry, from_group}) => {
  let { data } = entry

  return (
    <li className="bg-light p-2 text-center">
      <small className="mb-1 text-secondary">
        Task Created {entry.created_at_label}
      </small>
    </li>
  );
}

export default ProjectTasksCreated
