import React, { useState, useEffect } from 'react'
import boards_api from '../../../utils/pm_boards_api'

const getQueueRecords = (project_queue_id, filters) => {
  const [records, setRecords] = useState([])
  const [loading, setLoading] = useState(true)

  useEffect(() => {
    setLoading(true)

    boards_api.getQueueMapRecords(project_queue_id, filters).then((response) => {
      setRecords(response.data.map((t) => t.attributes))
      setLoading(false)
    })
  }, [filters])

  return [loading, records, setRecords]
}

export default {
  getQueueRecords
}
