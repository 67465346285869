import React from "react";
import ProjectTasksCreated from "./ProjectTasksCreated";
import ProjectTasksStateChanged from "./ProjectTasksStateChanged";
import ProjectTasksUserAssigned from "./ProjectTasksUserAssigned";
import ProjectTasksContractorAssigned from "./ProjectTasksContractorAssigned";
import ProjectTasksRelationshipAssigned from "./ProjectTasksRelationshipAssigned";
import ProjectTasksActionApplied from "./ProjectTasksActionApplied";
import ProjectTasksWorkflowExecuted from "./ProjectTasksWorkflowExecuted";
import ProjectTasksTriggerMatched from "./ProjectTasksTriggerMatched";
import AttachmentsUploaded from "./AttachmentsUploaded";
import CommentsWritten from "./CommentsWritten";
import ProjectTasksSubmissionDataUpdated from "./ProjectTasksSubmissionDataUpdated";
import ProjectTasksScheduleDateSet from "./ProjectTasksScheduleDateSet";
import ProjectTasksScheduleDurationSet from "./ProjectTasksScheduleDurationSet";
import SubmissionProcessed from "./SubmissionProcessed";
import ProjectTasksSubmissionReceived from "./ProjectTaskSubmissionReceived";
import ProjectTasksWorkflowApplied from "./ProjectTasksWorkflowApplied";
import StickersApplied from "./StickersApplied";
import ProjectTasksAttachmentCreated from "./ProjectTasksAttachmentCreated";
import ProjectTasksEmailNotificationSent from "./ProjectTasksEmailNotificationSent";

const IndividualEntry = ({entry, from_group}) => {
  switch (entry.event) {
    case "ProjectTasks::Events::StateChanged":
      return <ProjectTasksStateChanged entry={entry} from_group={from_group}/>;
      break;
    case "ProjectTasks::Events::EmailNotificationSent":
      return <ProjectTasksEmailNotificationSent entry={entry} from_group={from_group}/>;
      break;
    case "ProjectTasks::Events::TaskAttachmentCreated":
      return <ProjectTasksAttachmentCreated entry={entry} from_group={from_group}/>;
      break;
    case "ProjectTasks::Events::UserAssigned":
      return <ProjectTasksUserAssigned entry={entry} from_group={from_group} />;
      break;
    case "ProjectTasks::Events::ContractorAssigned":
      return <ProjectTasksContractorAssigned entry={entry} from_group={from_group} />;
      break;
    case "ProjectTasks::Events::RelationshipAssigned":
      return <ProjectTasksRelationshipAssigned entry={entry} from_group={from_group} />;
      break;
    case "ProjectTasks::Events::ActionApplied":
      return <ProjectTasksActionApplied entry={entry} from_group={from_group} />;
      break;
    case "ProjectTasks::Events::TriggerMatched":
      return <ProjectTasksTriggerMatched entry={entry} from_group={from_group} />;
      break;
    case "ProjectTasks::Events::WorkflowExecuted":
      return <ProjectTasksWorkflowExecuted entry={entry} from_group={from_group} />;
      break;
    case "ProjectTasks::Events::WorkflowApplied":
      return <ProjectTasksWorkflowApplied entry={entry} from_group={from_group} />;
      break;
    case "ProjectTasks::Events::SubmissionDataUpdated":
      return <ProjectTasksSubmissionDataUpdated entry={entry} from_group={from_group} />;
      break;
    case "ProjectTasks::Events::ScheduleDateSet":
      return <ProjectTasksScheduleDateSet entry={entry} from_group={from_group} />;
      break;
    case "ProjectTasks::Events::ScheduleDurationSet":
      return <ProjectTasksScheduleDurationSet entry={entry} from_group={from_group} />;
      break;
    case "Attachments::Events::Uploaded":
      return <AttachmentsUploaded entry={entry} from_group={from_group} />;
      break;
    case "Stickers::Events::Applied":
      return <StickersApplied entry={entry} from_group={from_group} />;
      break;
    case "Comments::Events::Written":
      return <CommentsWritten entry={entry} from_group={from_group} />;
      break;
    case "ProjectTasks::Events::SubmissionReceived":
      return <ProjectTasksSubmissionReceived entry={entry} from_group={from_group} />;
      break;
    case "Submissions::Events::Processed":
      return <SubmissionProcessed entry={entry} from_group={from_group} />;
      break;
    case "ProjectTasks::Events::Created":
      return <ProjectTasksCreated entry={entry} from_group={from_group} />;
      break;
  }

  return (
    <li className="timeline-item mb-2">
      <h6 className="fw-bold mb-1" style={{lineHeight: 1.0}}>Missing for event {entry.event}</h6>
      <small className="text-muted mb-1 fw-bold">{entry.created_at_label} | {entry.request_ip}</small>
    </li>
  );
}

const ActivityEntry = ({group}) => {
  if(group.length == 1) {
    return(<IndividualEntry entry={group[0]} />)
  }

  return (
    <li className="timeline-item mb-2">
      <h6 className="mb-1">
        {group.length} events
      </h6>
      <small className="text-muted mb-1 fw-bold">
      {group[0].current_user.name}{" "} · {group[0].created_at_label}
      </small>

      <ul className="timeline-basic-example my-4">
        {group.reverse().map((entry, v) => {
          return <IndividualEntry entry={entry} key={v} from_group={true}/>;
        })}
      </ul>
    </li>
  );

}

export default ActivityEntry
