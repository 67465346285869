import React, { Component, Fragment } from 'react'
import { render } from 'react-dom'
import Modal from 'react-bootstrap/Modal'
import api from '../../../utils/pm_api'

import Select from 'react-select'
import _ from 'lodash'

import { DateTimePicker } from '@atlaskit/datetime-picker'

const times = ["07:00", "07:30", "08:00", "08:30","09:00", "09:30", "10:00", "10:30", "11:00", "11:30", "12:00", "12:30", "13:00", "13:30", "14:00", "14:30", "15:00", "15:30", "16:00", "16:30", "17:00", "17:30", "18:00"]

const formatOptionLabel = ({ value, label, customAbbreviation }) => (
  <div style={{ display: "flex" }}>
    <div>{label}</div>
    <div style={{ marginLeft: "10px", color: "#ccc" }}>
      {customAbbreviation}
    </div>
  </div>
);

const selectStyles = {
  control: (styles, {isDisabled, isFocused}) => {
    let backgroundColor = isDisabled ? 'hsl(0,0%,95%)' : '#fbfcfe'
    return {
      ...styles,
      backgroundColor: backgroundColor,
    }
  },
  multiValue: (styles, { data }) => {
    return {
      ...styles,
      backgroundColor: 'hsl(0,0%,95%)',
    };
  },
  multiValueLabel: (styles, { data }) => ({
    ...styles,
    fontSize: '16px',
    fontWeight: '600',
  })
}

class ModalRow extends Component {
  constructor(props) {
    super(props)
    let show_multiple_label = _.uniq(props.editables.map(editable => editable[props.name])).length > 1
    if(props.name == 'user_and_sub_organization') {
      show_multiple_label = _.uniq(props.editables.map((editable) => {
        if(editable[props.name]) {
          return editable[props.name].value
        } else {
          return editable[props.name]
        }
      })).length > 1
    }

    this.state = {
      required: props.required,
      name: props.name,
      multiple: props.editables.length > 1,
      show_multiple_label: show_multiple_label,
      show_message: props.schema.showMessage,
      message_title: props.schema.messageTitle,
      message: props.schema.message,
    }

    this.inputType = this.inputType.bind(this)
  }

  inputType(is_invalid) {
    let { schema, invalid_fields } = this.props
    let invalid_class = is_invalid ? ' is-invalid' : ''
    let input = (
      <input
        className={'form-control' + invalid_class}
        onChange={(e) => this.props.updateValue(this.props.name, schema.type, e)}
        value={this.props.value} />
    )
    if(schema.type == 'text') {
      input = (
        <textarea
          className={'form-control' + invalid_class}
          onChange={(e) => this.props.updateValue(this.props.name, schema.type, e)}
          value={this.props.value}
        >
        </textarea>
      )
    } else if (schema.type == 'email') {
      input = (
        <input
          className={'form-control' + invalid_class}
          type='email'
          onChange={(e) => this.props.updateValue(this.props.name, schema.type, e)}
          value={this.props.value} />
      )

    } else if (schema.type == 'datetime') {
      const [datePart, timePart] = (this.props.value || "").split('T');
      let timeWithoutTimezone = datePart ? `${datePart}T${timePart.split('-')[0]}` : ""

      input = (
        <DateTimePicker
          times={times}
          id="datetimepicker-1"
          onChange={(e) => this.props.updateValue(this.props.name, schema.type, e)}
          value={timeWithoutTimezone}
        />
      )


    } else if (schema.type == 'select') {
      if(schema.mixed) {
        input = (
          <Select
            styles={selectStyles}
            value={this.props.value}
            onChange={(e) => this.props.updateValue(this.props.name, schema.type, e)}
            options={schema.options}
            isClearable={schema.isClearable}
            formatOptionLabel={formatOptionLabel}
          />
        )
      } else {
        input = (
          <Select
            styles={selectStyles}
            value={this.props.value}
            onChange={(e) => this.props.updateValue(this.props.name, schema.type, e)}
            options={schema.options}
            isClearable={schema.isClearable}
          />
        )
      }
    }

    return input
  }

  render() {
    let { schema, invalid_fields } = this.props
    let is_invalid = invalid_fields.includes(this.props.name)
    if(schema.hide) {
      return(<Fragment></Fragment>)
    }

    let label = 'This field is required.'

    if(is_invalid && schema.type == 'email') {
      label = 'This field is required and should be an email.'
    }


    return(
      <div className="form-group">
        <label>{schema.label}</label>
        { this.state.show_message ? (
          <div className="multiple-values text-muted py-2 px-3">
            <p>{this.state.message_title}</p>
            <small>{this.state.message}</small>
          </div>
        ) : (
          <Fragment>
            { this.state.show_multiple_label ? (
              <div className="multiple-values py-2 px-3" onClick={() => this.setState({show_multiple_label: false})}>
                <h6>Multiple values</h6>
                <p className='text-muted'>The selected items contain different values for this input. To edit and set all items to a specific value, click or tap here.</p>
              </div>
            ) : (
              <Fragment>
                {this.inputType(is_invalid)}
                { is_invalid &&
                  <div className="invalid-feedback">
                    {label}
                  </div>
                }
              </Fragment>
            )}
          </Fragment>
        )}
      </div>
    )
  }
}

export default class BaseModal extends Component {
  constructor(props) {
    super(props)
    let model = {}
    Object.keys(props.schema.properties).forEach((name) => {
      let values = _.uniq(props.editables.map(editable => editable[name]))
      if(name == 'user_and_sub_organization') {
        values = _.uniq(props.editables.map(editable => {
          if(editable[name]) {
            return editable[name].value
          } else {
            return editable[name]
          }
        }))
      }

      if(values.length == 1) {
        let schema = props.schema.properties[name]
        if(schema.type == 'select' && !schema.mixed) {
          model[name] = schema.options.find(op => op.value == values[0])
        } else if(schema.mixed) {
          model[name] = props.editables[0][name] || ''
        }  else {
          model[name] = values[0] || ''
        }
      } else {
        model[name] = undefined
      }
    })

    this.state = {
      model: model,
      edited_names: [],
      invalid_fields: [],
      disabled: true,
      processing: false,
    }

    this.handleSave = this.handleSave.bind(this)
    this.modalTitle = this.modalTitle.bind(this)
    this.handleClose = this.handleClose.bind(this)
    this.getValue = this.getValue.bind(this)
    this.updateValue = this.updateValue.bind(this)
  }

  handleClose() {
    if(this.state.processing) {
      return
    }

    this.setState({
      model: {
      },
      edited_names: [],
    }, () => {
      this.props.closeModal()
    })
  }

  handleSave(e) {
    e.preventDefault()
    if(this.state.disabled || this.state.processing) { return }
    let invalid_fields = []
    if(this.props.parent_handle_save) {
      this.props.schema.required.forEach((field) => {
        if(!this.state.model[field]) {
          invalid_fields.push(field)
        }

        //Momentary fix, this should be move on schema validations
        if(field == 'contact_email' && this.state.model[field]) {
          let regex = /\S+@\S+\.\S+/
          if(!regex.test(this.state.model[field])) {
            invalid_fields.push(field)
          }
        }
      })
      if(invalid_fields.length > 0 ) {
        this.setState({invalid_fields: invalid_fields})
      } else {
        this.props.handleSave(this.state.model, this.state.edited_names)
      }
    } else {
      let data = {
        model: this.state.model,
        edited_names: this.state.edited_names,
        ids: this.props.editables.map(editable => editable.id),
        resource_type: this.props.resource_type,
      }

      this.setState({processing: true}, () => {
        api.updateBatch(data).then((response) => {
          this.setState({
            model: {
            },
            edited_names: [],
            processing: false,
          }, () => {
            this.props.handleSave()
          })
        })
      })
    }
  }

  updateValue(name, type, e) {
    if(this.props.disabled) {
      return
    }
    let { model, edited_names } = this.state
    edited_names.push(name)
    edited_names = _.uniq(edited_names)
    if(type == 'text') {
      model[name] = e.target.value
    } else if(type == 'string') {
      model[name] = e.target.value
    } else if(type == 'email') {
      model[name] = e.target.value
    } else if(type == 'select') {
      model[name] = e
    } else if(type == 'datetime') {
      model[name] = e
    }
    this.setState({model, edited_names, disabled: false})
  }

  getValue(name) {
    return this.state.model[name]
  }

  modalTitle() {
    if(this.props.editables.length == 1) {
      return this.props.editables[0].name
    } else {
      return `Editing ${this.props.editables.length} ${this.props.resource_type_label}`
    }
  }

  render() {
    let { schema } = this.props
    let { disabled, processing } = this.state
    let properties = Object.keys(schema.properties)
    let save_label = processing ? 'Saving changes' : 'Save changes'
    disabled = disabled || this.props.disabled

    return(
      <Modal show={this.props.is_open} onHide={this.handleClose} animation={true}>
        <Modal.Header closeButton>
          <Modal.Title>
            {this.props.title || this.modalTitle()}
          </Modal.Title>
        </Modal.Header>
        <form className='pm-edit-modal'>
          <Modal.Body>
            { properties.map((prop, index) => {
              let value = this.getValue(prop)
              return(
                <ModalRow
                  editables={this.props.editables}
                  key={index}
                  required={schema.required.includes(prop)}
                  name={prop}
                  updateValue={this.updateValue}
                  value={value}
                  schema={schema.properties[prop]}
                  invalid_fields={this.state.invalid_fields}
                />
              )
            })}
          </Modal.Body>

          <Modal.Footer>
            { (processing || this.props.processing) ? (
              <span className='text-muted'>
                <i className='fa fa-spinner fa-spin fa-fw' style={{fontSize: '20px'}}></i>
              </span>
            ) : (
              <button onClick={this.handleClose} className="btn btn-secondary">Close</button>
            )}
            <button className="btn btn-primary" onClick={this.handleSave} disabled={disabled || processing}>{save_label}</button>
          </Modal.Footer>
        </form>
      </Modal>
    )
  }
}

