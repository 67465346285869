import React, { useState } from "react";
import Modal from "react-bootstrap/Modal";
import Button from "react-bootstrap/Button";
import moment from "moment";
import {
  useCreateEntry,
  useGetEntries,
  useUpdateEntry,
} from "../../hooks/useTimesheet";
import EntryForm from "./EntryForm";
import { formatDuration } from "../../utils";

const UpdateDateEntriesModal = ({
  user,
  date,
  onClose,
  work_type_id,
  removeFromNewUsers,
}) => {
  const { data: entries, isPending } = useGetEntries(user.user_id, date);
  const [currentEntry, setCurrentEntry] = useState(null);
  const [step, setStep] = useState(1); // 1 for entry form, 2 for confirmation
  const [notes, setNotes] = useState("");
  const [changeDetails, setChangeDetails] = useState({});

  const handleEntryEdit = (
    entryId,
    registered_times,
    originalMinutes,
    newMinutes
  ) => {
    const entry = entries.find((e) => e.id === entryId);
    setCurrentEntry({ ...entry, registered_times });
    setChangeDetails({ originalMinutes, newMinutes });
    setStep(2);
  };

  const { mutate: updateEntry, isPending: isSubmitting } = useUpdateEntry(
    user.user_id,
    date
  );

  const { mutate: createEntry, isPending: isCreating } = useCreateEntry(date);

  const handleEntryCreate = (
    entryId,
    registered_times,
    originalMinutes,
    newMinutes
  ) => {
    setCurrentEntry({
      id: entryId,
      date,
      user_id: user.user_id,
      registered_times,
    });
    setChangeDetails({ originalMinutes, newMinutes });
    setStep(2);
  };

  const confirmUpdate = () => {
    if (!currentEntry.id) {
      createEntry(
        {
          registered_times: currentEntry.registered_times,
          date: date,
          user_id: user.user_id,
          work_type_id,
        },
        {
          onSuccess: () => {
            removeFromNewUsers(user.user_id);
            setCurrentEntry(null);
            setNotes("");
            setStep(1);
            onClose();
          },
        }
      );
    } else {
      updateEntry(
        {
          entryId: currentEntry.id,
          data: {
            registered_times: currentEntry.registered_times,
            notes,
          },
        },
        {
          onSuccess: () => {
            setCurrentEntry(null);
            setNotes("");
            setStep(1);
            onClose();
          },
        }
      );
    }
  };

  const handleClose = () => {
    if (isSubmitting) return;
    onClose();
  };

  return (
    <Modal show={true} onHide={handleClose} animation={true}>
      <Modal.Header closeButton className="align-items-center">
        <div className="d-flex flex-column">
          <h5 className="mb-0">
            {moment(date).format("ddd D MMM, YYYY")} - {user.name}
          </h5>
          <div className="text-secondary" style={{ fontSize: "13px" }}>
            Timesheet
          </div>
        </div>
      </Modal.Header>
      <Modal.Body>
        {step === 1 && (
          <>
            {isPending && <div className="text-center my-4">Loading...</div>}
            {!isPending &&
              entries.map((entry) => (
                <EntryForm
                  key={entry.id}
                  entry={entry}
                  onSave={handleEntryEdit}
                />
              ))}

            {!isPending && entries.length === 0 && (
              <EntryForm
                entry={{
                  id: null,
                  date,
                  user_id: user.user_id,
                  registered_times: [],
                }}
                onSave={handleEntryCreate}
              />
            )}
          </>
        )}
        {step === 2 && currentEntry && (
          <>
            {currentEntry.certified_by_user_at && (
              <div className="alert alert-warning">
                This entry has been certified by the user
              </div>
            )}

            <span>
              Change entry from{" "}
              <strong>{formatDuration(changeDetails.originalMinutes)}</strong>{" "}
              to <strong>{formatDuration(changeDetails.newMinutes)}</strong>
            </span>

            <textarea
              className="form-control my-3"
              value={notes}
              onChange={(e) => setNotes(e.target.value)}
              placeholder="Add a note"
            ></textarea>
            <div className="mt-3 d-flex justify-content-end">
              <Button
                variant="secondary"
                className="mr-2"
                onClick={() => setStep(1)}
                disabled={isSubmitting}
              >
                Cancel
              </Button>
              <Button
                variant="primary"
                onClick={confirmUpdate}
                disabled={isSubmitting}
              >
                Confirm
              </Button>
            </div>
          </>
        )}
      </Modal.Body>
    </Modal>
  );
};

export default UpdateDateEntriesModal;
