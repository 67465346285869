import React, { useEffect, useState } from "react";
import IntervalGroup from "./IntervalGroup";
import RegisterNewUserModal from "./RegisterNewUserModal";

const Zone = ({
  report,
  handleDirection,
  newUsers,
  datasources,
  removeFromNewUsers,
}) => {
  let { users, total_hours, report_label, date } = report;

  return (
    <>
      <div className="row">
        <div className="col-12">
          <IntervalGroup
            users={users}
            report_label={report_label}
            handleDirection={handleDirection}
            newUsers={newUsers}
            datasources={datasources}
            removeFromNewUsers={removeFromNewUsers}
          />
        </div>
      </div>

      {users.length !== 0 ? (
        <div className="row mt-1">
          <div className="col-12 d-flex justify-content-end">
            <div className="font-weight-bold">
              <span className="mr-4">Total</span>
              <span className="mr-2 pr-2">{total_hours}</span>
            </div>
          </div>
        </div>
      ) : (
        <>
          {newUsers.length == 0 && (
            <div className="row mt-4">
              <div className="col-12">
                <div className="alert alert-warning">No entries found</div>
              </div>
            </div>
          )}
        </>
      )}
    </>
  );
};

const getUsersNotInReport = (datasources, newUsers, report) => {
  if (!datasources || !report) return [];

  const reportUserIds = report.users.map((u) => u.user_id);
  return (datasources.users || []).filter(
    (user) =>
      !reportUserIds.includes(user.value) &&
      !newUsers.find((u) => u.user_id === user.value)
  );
};

const WeekReport = ({ report, datasources, handleDirection, currentDate }) => {
  const [openNewUserModal, setOpenNewUserModal] = useState(false);
  const [filteredUsers, setFilteredUsers] = useState([]);
  const [newUsers, setNewUsers] = useState([]);

  useEffect(() => {
    const usersNotInReport = getUsersNotInReport(datasources, newUsers, report);
    setFilteredUsers(usersNotInReport);
  }, [datasources, report, newUsers]);

  useEffect(() => {
    setNewUsers([]);
  }, [currentDate]);

  const handleNewUser = ({ user, workType }) => {
    const dates = Array.from({ length: 7 }, (_, i) =>
      currentDate.clone().startOf("isoWeek").add(i, "days").format("YYYY-MM-DD")
    );
    setNewUsers([
      ...newUsers,
      {
        user_id: user.value,
        name: user.label,
        minutes: [0, 0, 0, 0, 0, 0, 0],
        work_type_id: workType.value,
        dates,
      },
    ]);

    setOpenNewUserModal(false);
  };

  const removeFromNewUsers = (user_id) => {
    setNewUsers(newUsers.filter((u) => u.user_id !== user_id));
  };

  return (
    <div className="row mt-4">
      <div className="col-12">
        <div className="card">
          <div className="card-body">
            <Zone
              report={report}
              handleDirection={handleDirection}
              newUsers={newUsers}
              datasources={datasources}
              removeFromNewUsers={removeFromNewUsers}
            />

            <div className="row mt-2">
              <div className="col-12">
                <a
                  className="text-primary cursor-pointer"
                  onClick={() => setOpenNewUserModal(true)}
                >
                  Add new user entry
                </a>
              </div>
            </div>

            {openNewUserModal && (
              <RegisterNewUserModal
                users={filteredUsers}
                work_types={datasources.work_types}
                onClose={() => setOpenNewUserModal(false)}
                onSubmit={handleNewUser}
              />
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default WeekReport;
