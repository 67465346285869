import React from "react";
import moment from 'moment'
import RightArrowSvg from "./right_arrow.svg";

const ProjectTasksScheduleDateSet = ({entry, from_group}) => {
  let { data } = entry
  let { new_scheduled_at, past_scheduled_at } = data

  return (
    <li className="timeline-item mb-4">
      <h6 className="mb-1 small">
        schedule date set
      </h6>

      <div>
        
        {past_scheduled_at
          ? moment(past_scheduled_at).format("MMM DD YYYY, h:mm a")
          : "No schedule"}
      
        <img src={RightArrowSvg} />

        {new_scheduled_at
          ? moment(new_scheduled_at).format("MMMM Do YYYY, h:mm a")
          : "No schedule"}
        
      </div>

      {!from_group && (
        <small className="text-muted mb-1 fw-bold">
          {entry.current_user.name} · {entry.created_at_label}
        </small>
      )}
    </li>
  );
}

export default ProjectTasksScheduleDateSet
