import React from "react";

const SubmissionProcessed = ({entry, from_group}) => {
  let { data } = entry

  return (
    <li className="timeline-item mb-4">
      <h6 className="mb-1 small">
        submission received
      </h6>

      {!from_group && (
        <small className="text-muted mb-1 fw-bold">
         {entry.current_user.name} · {entry.created_at_label}
        </small>
      )}
    </li>
  );
}

export default SubmissionProcessed
