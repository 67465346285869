import React from "react";
import RightArrowSvg from "./right_arrow.svg";

const ProjectTasksContractorAssigned = ({entry, from_group}) => {
  let { data } = entry
  let { new_contractor, past_contractor } = data

  return (
    <li className="timeline-item mb-4">
      <h6 className="mb-1 small">
        updated assigment
      </h6>

      <div>
        {past_contractor}
        <img src={RightArrowSvg} />
        {new_contractor}
      </div>

      {!from_group && (
        <small className="text-muted mb-1 fw-bold">
          {entry.current_user.name} · {entry.created_at_label}
        </small>
      )}
    </li>
  );
}

export default ProjectTasksContractorAssigned
