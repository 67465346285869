import React from "react";

const ProjectTasksSubmissionDataUpdated = ({entry, from_group}) => {
  let { data, current_user } = entry
  let { attachment } = data

  return (
    <li className="timeline-item mb-4">
      <h6 className="mb-1 small">
        form data updated
      </h6>

      {!from_group && (
        <small className="text-muted mb-1 fw-bold">
          {entry.current_user.name} · {entry.created_at_label}
        </small>
      )}
    </li>
  );
}

export default ProjectTasksSubmissionDataUpdated
